<template>
  <!-- Autres contacts à rappeler -->
  <div class="fs-6 d-flex flex-column-fluid mt-10">
    <div class="container-fluid">
      <div class="card">
        <div
          class="card-header border-0 pt-6"
          style="background-color: #33d73e"
        >
          <div class="card-title mb-5">
            <div class="d-flex align-items-center position-relative my-1">
              <h1 class="fw-bolder my-1 fs-2 text-white">
                Autres contacts à rappeler
              </h1>
            </div>
          </div>
          <div class="card-toolbar mb-5">
            <div class="d-flex justify-content-end">
              <div class="me-8 d-flex align-items-center">
                <input
                  v-model="scrollValue"
                  type="range"
                  min="0"
                  :max="maxScroll"
                  class="form-range"
                  @input="scrollTable"
                />
              </div>
              <span
                v-if="callBackDatesLeft"
                class="fw-bolder my-1 fs-2 text-white"
                >{{ callBackDatesLeft.count }}</span
              >
            </div>
          </div>
        </div>

        <!-- Loader -->
        <Loader :data="callBackDatesLeft" />

        <div v-if="callBackDatesLeft">
          <div v-if="callBackDatesLeft.count > 0">
            <div class="card-body p-0">
              <div
                ref="callbackDatesLeftTableResponsive"
                class="table-responsive"
                @scroll="onTableScroll"
              >
                <table
                  v-if="callBackDatesLeft.results"
                  class="table table-flush align-middle table-row-bordered table-row-solid gy-4"
                >
                  <div class="mh-300px overflow-scroll">
                    <thead>
                      <tr class="text-start fw-bolder fs-6 text-uppercase gs-0">
                        <th class="min-w-125px ps-9">
                          <div
                            class="d-flex align-items-center justify-content-between"
                          >
                            Date de rappel
                            <span class="d-flex row" style="cursor: pointer">
                              <i
                                class="bi bi-caret-up-fill"
                                @click.prevent="ascendingDate"
                              ></i>
                              <i
                                class="bi bi-caret-down-fill"
                                @click.prevent="ascendingDate"
                              ></i>
                            </span>
                          </div>
                        </th>
                        <th class="min-w-125px ps-9">Nom</th>
                        <th class="min-w-125px ps-9">Prénom</th>
                        <th class="min-w-125px ps-9">Téléphone</th>
                        <th class="min-w-125px ps-9">Mail</th>
                        <th class="min-w-125px ps-9">Promoteur</th>
                        <th class="min-w-125px ps-9">Agence</th>
                        <th class="min-w-125px ps-9">Poste</th>
                        <th class="min-w-125px ps-9">
                          Date du dernier échange
                        </th>
                      </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-700">
                      <tr
                        v-for="callBackDate in callBackDatesLeft.results"
                        :key="callBackDate.id"
                      >
                        <td class="min-w-125px ps-9">
                          <router-link
                            :to="'/contacts/' + callBackDate.contact.id"
                            class="text-black-50"
                            ><span v-if="callBackDate.value">
                              {{ getMomentDate(callBackDate.value) }}</span
                            ><span v-else>Non renseigné</span>
                          </router-link>
                        </td>
                        <td class="w-250px min-w-175px ps-9">
                          <router-link
                            :to="'/contacts/' + callBackDate.contact.id"
                            class="text-black-50"
                            ><span v-if="callBackDate.contact.last_name">
                              {{ callBackDate.contact.last_name }}</span
                            ><span v-else>Non renseigné</span>
                          </router-link>
                        </td>
                        <td class="w-250px min-w-175px ps-9">
                          <router-link
                            :to="'/contacts/' + callBackDate.contact.id"
                            class="text-black-50"
                            ><span v-if="callBackDate.contact.first_name">{{
                              callBackDate.contact.first_name
                            }}</span
                            ><span v-else>Non renseigné</span></router-link
                          >
                        </td>
                        <td class="w-250px min-w-175px ps-9">
                          <router-link
                            :to="'/contacts/' + callBackDate.contact.id"
                            class="text-black-50"
                            ><span v-if="callBackDate.contact.phone_number">{{
                              callBackDate.contact.phone_number
                            }}</span
                            ><span v-else>Non renseigné</span></router-link
                          >
                        </td>
                        <td class="w-250px min-w-175px ps-9">
                          <router-link
                            :to="'/contacts/' + callBackDate.contact.id"
                            class="text-black-50"
                            ><span v-if="callBackDate.contact.email">{{
                              callBackDate.contact.email
                            }}</span
                            ><span v-else>Non renseigné</span></router-link
                          >
                        </td>
                        <td class="w-250px min-w-175px ps-9">
                          <router-link
                            :to="'/contacts/' + callBackDate.contact.id"
                            class="text-black-50"
                            ><span v-if="callBackDate.contact.promoter_name">{{
                              callBackDate.contact.promoter_name
                            }}</span
                            ><span v-else>Non renseigné</span></router-link
                          >
                        </td>
                        <td class="w-250px min-w-175px ps-9">
                          <router-link
                            :to="'/contacts/' + callBackDate.contact.id"
                            class="text-black-50"
                            ><span v-if="callBackDate.contact.agency">{{
                              callBackDate.contact.agency.name
                            }}</span
                            ><span v-else>Non renseigné</span></router-link
                          >
                        </td>
                        <td class="w-250px min-w-175px ps-9">
                          <router-link
                            :to="'/contacts/' + callBackDate.contact.id"
                            class="text-black-50"
                            ><span v-if="callBackDate.contact.position">{{
                              callBackDate.contact.position
                            }}</span
                            ><span v-else>Non renseigné</span></router-link
                          >
                        </td>
                        <td class="w-250px min-w-175px ps-9 pe-9">
                          <router-link
                            :to="'/contacts/' + callBackDate.contact.id"
                            class="text-black-50"
                            ><span v-if="callBackDate.contact.updated">{{
                              getMomentDate(callBackDate.contact.updated)
                            }}</span
                            ><span v-else>Non renseigné</span></router-link
                          >
                        </td>
                      </tr>
                    </tbody>
                  </div>
                </table>
              </div>
            </div>
            <div class="card-footer d-flex justify-content-end py-6 px-9">
              <div v-if="callBackDatesLeft">
                <button
                  v-if="callBackDatesLeft.previous"
                  class="btn btn-light btn-active-light-primary me-2"
                  @click.prevent="showPreviousData(callBackDatesLeft.previous)"
                >
                  Page précédente
                </button>
                <button
                  v-if="callBackDatesLeft.next"
                  class="btn btn-primary"
                  @click.prevent="showNextData(callBackDatesLeft.next)"
                >
                  Page suivante
                </button>
              </div>
            </div>
          </div>
          <div v-else class="card-body ms-1">
            <div class="alert alert-secondary">
              <div class="d-flex flex-column">
                <span class="mb-1 text-dark">Aucun contact à rappeler</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "@/components/Loader";
import store from "@/store";
import moment from "moment";

export default {
  name: "CallbackDatesLeft",
  components: {
    Loader,
  },
  data() {
    return {
      filter: {
        order: "",
        specific_case__in:
          "Bailleur social ou assimilé, Introuvable, LLS vendus, Opération achevée, Opération abandonnée, Intéressant vente bloc, Pas de LLS, Pas compétitifs, Autre non pertinent, Opération signée, Promoteur indélicat, LLS « dealé », Aucun cas spécifique",
        type: "callBackDatesLeft",
      },
      callBackDatesData: "",
      scrollValue: 0,
      maxScroll: 100,
    };
  },
  computed: {
    ...mapGetters(["callBackDatesLeft"]),
    ...mapGetters(["callBackDatesNegotiation"]),
    ...mapGetters(["callBackDatesFavoris"]),
  },
  mounted() {
    this.showCallBackDates();
  },
  methods: {
    ...mapActions(["getCallBackDates"]),
    ...mapActions(["nextPaginate"]),
    ...mapActions(["previousPaginate"]),

    async showCallBackDates() {
      store.commit("callBackDatesLeft", null);
      this.getCallBackDates(this.filter);
      while (
        !Array.isArray(this.callBackDatesNegotiation?.results) ||
        !Array.isArray(this.callBackDatesFavoris?.results)
      ) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      const excludedIds = [
        ...this.callBackDatesNegotiation?.results.map(
          (item) => item.contact.id
        ),
        ...this.callBackDatesFavoris?.results.map((item) => item.contact.id),
      ];

      this.callBackDatesLeft.results = this.callBackDatesLeft?.results.filter(
        (item) => !excludedIds.includes(item.contact.id)
      );
    },
    // Ordering
    ascendingDate() {
      this.filter.order = "value";
      this.showCallBackDates();
    },
    descendingDate() {
      this.filter.order = "-value";
      this.showCallBackDates();
    },
    // Paginations
    async showNextData(url) {
      this.callBackDatesData = await this.nextPaginate(url);
      store.commit("callBackDatesLeft", {
        ...this.callBackDatesData,
      });
    },
    async showPreviousData(url) {
      this.callBackDatesData = await this.previousPaginate(url);
      store.commit("callBackDatesLeft", {
        ...this.callBackDatesData,
      });
    },
    // Moment
    getMomentDate(value) {
      return moment(value).locale("FR").format("DD/MM/YYYY");
    },
    scrollTable() {
      const tableContainer = this.$refs.callbackDatesLeftTableResponsive;
      if (tableContainer) {
        const scrollPercentage = this.scrollValue / this.maxScroll;
        const maxScrollAmount =
          tableContainer.scrollWidth - tableContainer.clientWidth;
        const scrollAmount = maxScrollAmount * scrollPercentage;
        tableContainer.scrollLeft = scrollAmount;
      }
    },
    onTableScroll() {
      const tableContainer = this.$refs.callbackDatesLeftTableResponsive;
      if (tableContainer) {
        const maxScrollAmount =
          tableContainer.scrollWidth - tableContainer.clientWidth;
        if (maxScrollAmount > 0) {
          const scrollPercentage = tableContainer.scrollLeft / maxScrollAmount;
          this.scrollValue = scrollPercentage * this.maxScroll;
        }
      }
    },
  },
};
</script>
