<template>
  <div class="content fs-6 d-flex flex-column flex-column-fluid">
    <!-- Total contact CallbackDates-->
    <div class="toolbar">
      <div class="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
        <div
          class="d-flex flex-column align-items-start justify-content-center flex-wrap me-2"
        >
          <h1 class="text-dark fw-bolder my-1 fs-2">Contacts à rappeler</h1>
        </div>
        <div class="d-flex align-items-center flex-nowrap text-nowrap py-1">
          <span class="badge badge-primary">{{
            (callBackDates ? callBackDates.count : 0) +
            (citiesCallbackDates ? citiesCallbackDates.count : 0)
          }}</span>
        </div>
      </div>
    </div>

    <CallbackDatesNegotiation />
    <CallbackDatesFavoris />
    <CallbackDatesLeft />
    <CitiesCallbackDates />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CallbackDatesNegotiation from "@/components/callbackdates/CallbackDatesNegotiation";
import CallbackDatesFavoris from "@/components/callbackdates/CallbackDatesFavoris";
import CallbackDatesLeft from "@/components/callbackdates/CallbackDatesLeft";
import CitiesCallbackDates from "@/components/callbackdates/CitiesCallbackDates";

export default {
  name: "Home",
  components: {
    CallbackDatesNegotiation,
    CallbackDatesFavoris,
    CallbackDatesLeft,
    CitiesCallbackDates,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["callBackDates"]),
    ...mapGetters(["citiesCallbackDates"]),
    ...mapGetters(["cities"]),
  },
  mounted() {},
  methods: {
    ...mapActions(["getCallBackDates"]),
    ...mapActions(["getCities"]),
    ...mapActions(["getCitiesCallbackDates"]),
    ...mapActions(["nextPaginate"]),
    ...mapActions(["previousPaginate"]),
  },
};
</script>
