<template>
  <!-- Contacts à rappeler avec PC en cours de négociation -->
  <div class="fs-6 d-flex flex-column-fluid">
    <div class="container-fluid">
      <div class="card">
        <div
          class="card-header border-0 pt-6"
          style="background-color: #18ba70"
        >
          <div class="card-title mb-5">
            <div class="d-flex align-items-center position-relative my-1">
              <h1 class="fw-bolder my-1 fs-2 text-white">
                Contacts à rappeler avec PC en cours de négociation
              </h1>
            </div>
          </div>
          <div class="card-toolbar mb-5">
            <div class="d-flex justify-content-end">
              <div class="me-8 d-flex align-items-center">
                <input
                  v-model="scrollValue"
                  type="range"
                  min="0"
                  :max="maxScroll"
                  class="form-range"
                  @input="scrollTable"
                />
              </div>
              <span
                v-if="callBackDatesNegotiation"
                class="fw-bolder my-1 fs-2 text-white"
                >{{ callBackDatesNegotiation.count }}</span
              >
            </div>
          </div>
        </div>

        <!-- Loader -->
        <Loader :data="callBackDatesNegotiation" />

        <div v-if="callBackDatesNegotiation">
          <div v-if="callBackDatesNegotiation.count > 0">
            <div class="card-body p-0">
              <div
                ref="callbackDatesNegoTableResponsive"
                class="table-responsive"
                @scroll="onTableScroll"
              >
                <table
                  v-if="callBackDatesNegotiation.results"
                  class="table table-flush align-middle table-row-bordered table-row-solid gy-4"
                >
                  <div class="mh-300px overflow-scroll">
                    <thead>
                      <tr class="text-start fw-bolder fs-6 text-uppercase gs-0">
                        <th class="min-w-125px ps-9">
                          <div
                            class="d-flex align-items-center justify-content-between"
                          >
                            Date de rappel
                            <span class="d-flex row" style="cursor: pointer">
                              <i
                                class="bi bi-caret-up-fill"
                                @click.prevent="ascendingDate"
                              ></i>
                              <i
                                class="bi bi-caret-down-fill"
                                @click.prevent="ascendingDate"
                              ></i>
                            </span>
                          </div>
                        </th>
                        <th class="min-w-125px ps-9">Nom</th>
                        <th class="min-w-125px ps-9">Prénom</th>
                        <th class="min-w-125px ps-9">Téléphone</th>
                        <th class="min-w-125px ps-9">Mail</th>
                        <th class="min-w-125px ps-9">Promoteur</th>
                        <th class="min-w-125px ps-9">Agence</th>
                        <th class="min-w-125px ps-9">Poste</th>
                        <th class="min-w-125px ps-9">
                          Date du dernier échange
                        </th>
                      </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-700">
                      <tr
                        v-for="callBackDate in callBackDatesNegotiation.results"
                        :key="callBackDate.id"
                      >
                        <td class="min-w-125px ps-9">
                          <router-link
                            :to="'/contacts/' + callBackDate.contact.id"
                            class="text-black-50"
                            ><span v-if="callBackDate.value">
                              {{ getMomentDate(callBackDate.value) }}</span
                            ><span v-else>Non renseigné</span>
                          </router-link>
                        </td>
                        <td class="w-250px min-w-175px ps-9">
                          <router-link
                            :to="'/contacts/' + callBackDate.contact.id"
                            class="text-black-50"
                            ><span v-if="callBackDate.contact.last_name">
                              {{ callBackDate.contact.last_name }}</span
                            ><span v-else>Non renseigné</span>
                          </router-link>
                        </td>
                        <td class="w-250px min-w-175px ps-9">
                          <router-link
                            :to="'/contacts/' + callBackDate.contact.id"
                            class="text-black-50"
                            ><span v-if="callBackDate.contact.first_name">{{
                              callBackDate.contact.first_name
                            }}</span
                            ><span v-else>Non renseigné</span></router-link
                          >
                        </td>
                        <td class="w-250px min-w-175px ps-9">
                          <router-link
                            :to="'/contacts/' + callBackDate.contact.id"
                            class="text-black-50"
                            ><span v-if="callBackDate.contact.phone_number">{{
                              callBackDate.contact.phone_number
                            }}</span
                            ><span v-else>Non renseigné</span></router-link
                          >
                        </td>
                        <td class="w-250px min-w-175px ps-9">
                          <router-link
                            :to="'/contacts/' + callBackDate.contact.id"
                            class="text-black-50"
                            ><span v-if="callBackDate.contact.email">{{
                              callBackDate.contact.email
                            }}</span
                            ><span v-else>Non renseigné</span></router-link
                          >
                        </td>
                        <td class="w-250px min-w-175px ps-9">
                          <router-link
                            :to="'/contacts/' + callBackDate.contact.id"
                            class="text-black-50"
                            ><span v-if="callBackDate.contact.promoter_name">{{
                              callBackDate.contact.promoter_name
                            }}</span
                            ><span v-else>Non renseigné</span></router-link
                          >
                        </td>
                        <td class="w-250px min-w-175px ps-9">
                          <router-link
                            :to="'/contacts/' + callBackDate.contact.id"
                            class="text-black-50"
                            ><span v-if="callBackDate.contact.agency">{{
                              callBackDate.contact.agency.name
                            }}</span
                            ><span v-else>Non renseigné</span></router-link
                          >
                        </td>
                        <td class="w-250px min-w-175px ps-9">
                          <router-link
                            :to="'/contacts/' + callBackDate.contact.id"
                            class="text-black-50"
                            ><span v-if="callBackDate.contact.position">{{
                              callBackDate.contact.position
                            }}</span
                            ><span v-else>Non renseigné</span></router-link
                          >
                        </td>
                        <td class="w-250px min-w-175px ps-9 pe-9">
                          <router-link
                            :to="'/contacts/' + callBackDate.contact.id"
                            class="text-black-50"
                            ><span v-if="callBackDate.contact.updated">{{
                              getMomentDate(callBackDate.contact.updated)
                            }}</span
                            ><span v-else>Non renseigné</span></router-link
                          >
                        </td>
                      </tr>
                    </tbody>
                  </div>
                </table>
              </div>
            </div>
            <div class="card-footer d-flex justify-content-end py-6 px-9">
              <div v-if="callBackDatesNegotiation">
                <button
                  v-if="callBackDatesNegotiation.previous"
                  class="btn btn-light btn-active-light-primary me-2"
                  @click.prevent="
                    showPreviousData(callBackDatesNegotiation.previous)
                  "
                >
                  Page précédente
                </button>
                <button
                  v-if="callBackDatesNegotiation.next"
                  class="btn btn-primary"
                  @click.prevent="showNextData(callBackDatesNegotiation.next)"
                >
                  Page suivante
                </button>
              </div>
            </div>
          </div>
          <div v-else class="card-body ms-1">
            <div class="alert alert-secondary">
              <div class="d-flex flex-column">
                <span class="mb-1 text-dark">Aucun contact à rappeler</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "@/components/Loader";
import store from "@/store";
import moment from "moment";

export default {
  name: "CallbackDatesNegotiation",
  components: {
    Loader,
  },
  data() {
    return {
      filter: {
        order: "",
        specific_case__in: "Négociation en cours",
        type: "callBackDatesNegotiation",
      },
      callBackDatesData: "",
      scrollValue: 0,
      maxScroll: 100,
    };
  },
  computed: {
    ...mapGetters(["callBackDatesNegotiation"]),
  },
  mounted() {
    this.showCallBackDates();
  },
  methods: {
    ...mapActions(["getCallBackDates"]),
    ...mapActions(["nextPaginate"]),
    ...mapActions(["previousPaginate"]),
    showCallBackDates() {
      store.commit("callBackDatesNegotiation", null);
      this.getCallBackDates(this.filter);
      this.callBackDatesData = this.callBackDatesNegotiation;
    },
    // Ordering
    ascendingDate() {
      this.filter.order = "value";
      this.showCallBackDates();
    },
    descendingDate() {
      this.filter.order = "-value";
      this.showCallBackDates();
    },
    async showNextData(url) {
      this.callBackDatesData = await this.nextPaginate(url);
      store.commit("callBackDatesNegotiation", {
        ...this.callBackDatesData,
      });
    },
    async showPreviousData(url) {
      this.callBackDatesData = await this.previousPaginate(url);
      store.commit("callBackDatesNegotiation", {
        ...this.callBackDatesData,
      });
    },
    getMomentDate(value) {
      return moment(value).locale("FR").format("DD/MM/YYYY");
    },
    scrollTable() {
      const tableContainer = this.$refs.callbackDatesNegoTableResponsive;
      if (tableContainer) {
        const scrollPercentage = this.scrollValue / this.maxScroll;
        const maxScrollAmount =
          tableContainer.scrollWidth - tableContainer.clientWidth;
        const scrollAmount = maxScrollAmount * scrollPercentage;
        tableContainer.scrollLeft = scrollAmount;
      }
    },
    onTableScroll() {
      const tableContainer = this.$refs.callbackDatesNegoTableResponsive;
      if (tableContainer) {
        const maxScrollAmount =
          tableContainer.scrollWidth - tableContainer.clientWidth;
        if (maxScrollAmount > 0) {
          const scrollPercentage = tableContainer.scrollLeft / maxScrollAmount;
          this.scrollValue = scrollPercentage * this.maxScroll;
        }
      }
    },
  },
};
</script>
