<template>
  <!-- Contact Mairie CallbackDates -->
  <div class="fs-6 d-flex flex-column-fluid mt-10">
    <div class="container-fluid">
      <div class="card">
        <div class="card-header border-0 pt-6 bg-primary">
          <div class="card-title mb-5">
            <div class="d-flex align-items-center position-relative my-1">
              <h1 class="fw-bolder my-1 fs-2 text-white">
                Contacts Mairie à rappeler
              </h1>
            </div>
          </div>
          <div class="card-toolbar mb-5">
            <div class="d-flex justify-content-end">
              <span
                v-if="citiesCallbackDates"
                class="fw-bolder my-1 fs-2 text-white"
                >{{ citiesCallbackDates.count }}</span
              >
            </div>
          </div>
        </div>

        <!-- Loader -->
        <Loader :data="citiesCallbackDates" />

        <div v-if="citiesCallbackDates">
          <div v-if="citiesCallbackDates.count > 0">
            <div class="card-body p-0">
              <div class="table-responsive">
                <div class="mh-300px overflow-scroll">
                  <table
                    v-if="citiesCallbackDates.results"
                    class="table table-flush align-middle table-row-bordered table-row-solid gy-4"
                  >
                    <thead>
                      <tr class="text-start fw-bolder fs-6 text-uppercase gs-0">
                        <th class="min-w-125px ps-9">
                          <div
                            class="d-flex align-items-center justify-content-between"
                          >
                            Date de rappel
                            <span class="d-flex row" style="cursor: pointer">
                              <i
                                class="bi bi-caret-up-fill"
                                @click.prevent="ascendingCityDate"
                              ></i>
                              <i
                                class="bi bi-caret-down-fill"
                                @click.prevent="descendingCityDate"
                              ></i>
                            </span>
                          </div>
                        </th>
                        <th class="min-w-125px ps-9">Mairie</th>
                      </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-700">
                      <tr
                        v-for="callBackDate in citiesCallbackDates.results"
                        :key="callBackDate.id"
                      >
                        <td class="w-125px min-w-175px ps-9">
                          <router-link
                            :to="'/cities/' + callBackDate.city"
                            class="text-black-50"
                            ><span v-if="callBackDate.value">
                              {{ getMomentDate(callBackDate.value) }}</span
                            ><span v-else>Non renseigné</span>
                          </router-link>
                        </td>
                        <td class="w-125px min-w-175px ps-9">
                          <router-link
                            :to="'/cities/' + callBackDate.city"
                            class="text-black-50"
                            ><span v-if="callBackDate.city">
                              {{ getCity(callBackDate.city) }}</span
                            ><span v-else>Non renseigné</span>
                          </router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="card-footer d-flex justify-content-end py-6 px-9">
              <div v-if="citiesCallbackDates">
                <button
                  v-if="citiesCallbackDates.previous"
                  class="btn btn-light btn-active-light-primary me-2"
                  @click.prevent="
                    showPreviousCityData(citiesCallbackDates.previous)
                  "
                >
                  Page précédente
                </button>
                <button
                  v-if="citiesCallbackDates.next"
                  class="btn btn-primary"
                  @click.prevent="showNextCityData(citiesCallbackDates.next)"
                >
                  Page suivante
                </button>
              </div>
            </div>
          </div>
          <div v-else class="card-body ms-1">
            <div class="alert alert-secondary">
              <div class="d-flex flex-column">
                <span class="mb-1 text-dark">Aucun contact à rappeler</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "@/components/Loader";
import store from "@/store";
import moment from "moment";

export default {
  name: "CitiesCallbackDates",
  components: {
    Loader,
  },
  data() {
    return {
      callBackDatesData: "",
      cityFilter: {
        size: "",
        defaultSize: 30,
        page: "",
        order: "",
      },
      citiesFilter: {
        order: "",
        size: "",
        defaultSize: 100,
        page: "",
      },
    };
  },
  computed: {
    ...mapGetters(["citiesCallbackDates"]),
    ...mapGetters(["cities"]),
  },
  mounted() {
    this.showCitiesCallbackDates();
    this.getAllCities();
  },
  methods: {
    ...mapActions(["getCitiesCallbackDates"]),
    ...mapActions(["getCities"]),
    ...mapActions(["nextPaginate"]),
    ...mapActions(["previousPaginate"]),
    // Cities
    async showCitiesCallbackDates() {
      await this.getCitiesCallbackDates(this.cityFilter);
    },
    getCity(id) {
      if (this.cities && this.cities.results) {
        const city = this.cities.results.find((item) => item.id === id);
        if (city) {
          return city.libcom_2014;
        } else {
          return "Non trouvée";
        }
      }
    },
    async getAllCities() {
      await this.getCities(this.citiesFilter);
    },
    // Ordering
    ascendingCityDate() {
      this.cityFilter.order = "value";
      this.showCitiesCallbackDates();
    },
    descendingCityDate() {
      this.cityFilter.order = "-value";
      this.showCitiesCallbackDates();
    },
    // Paginations
    async showNextCityData(url) {
      this.callBackDatesData = await this.nextPaginate(url);
      store.commit("citiesCallbackDates", {
        ...this.callBackDatesData,
      });
    },
    async showPreviousCityData(url) {
      this.callBackDatesData = await this.previousPaginate(url);
      store.commit("citiesCallbackDates", {
        ...this.callBackDatesData,
      });
    },
    // Moment
    getMomentDate(value) {
      return moment(value).locale("FR").format("DD/MM/YYYY");
    },
  },
};
</script>
